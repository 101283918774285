import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/login';
import BasicLayout from './layouts/BasicLayout';
import 'antd/dist/reset.css';
import { Spin } from 'antd';

// 使用 lazy 加载组件
const DashboardPage = React.lazy(() => import('./pages/dashboard'));
const UserPage = React.lazy(() => import('./pages/user'));
const SettingsPage = React.lazy(() => import('./pages/settings'));
const WalletManagement = React.lazy(() => import('./pages/wallet/WalletManagement'));
const TwitterManagement = React.lazy(() => import('./pages/twitter/index'));
const GalxeTask = React.lazy(() => import('./pages/galxepool/index'));
const Galxe = React.lazy(() => import('./pages/galxe/index'));
const PermissionPage = React.lazy(() => import('./pages/permission/index'));

// 添加权限控制组件
const PrivateRoute = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    // 减少验证时间
    const checkAuth = async () => {
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  // 移除 PrivateRoute 的 loading 状态显示
  if (isLoading) return null;
  
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  
  return element;
};

const App = () => {
  return (
    <Router>
      {/* 调整 Suspense 的 fallback 样式 */}
      <Suspense 
        fallback={
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}>
            <Spin size="large" />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={<PrivateRoute element={<BasicLayout />} />}
          >
            {/* 预加载其他路由组件 */}
            <Route path="dashboard" element={
              <DashboardPage />
            } />
            <Route path="user" element={
              <UserPage />
            } />
            <Route path="settings" element={
              <SettingsPage />
            } />
            <Route path="wallet" element={<WalletManagement />} />
            <Route path="twitter" element={<TwitterManagement />} />
            <Route path="galaxy" element={<Galxe />} />
            <Route path="galaxy-tasks" element={<GalxeTask />} />
            <Route path="permission" element={<PermissionPage />} />
            <Route index element={<Navigate to="/dashboard" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App; 